import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';

const OrderDetailsModal = webCore.helpers.dynamic(
  () => import('~components/Seller/OrderList/OrderDetailsModal')
);
const SellerOrderDetailsModal = webCore.helpers.dynamic(
  () => import('~components/SellerOrderDetailsModal')
);

const PendingOrderModal = webCore.helpers.dynamic(
  () => import('~components/Seller/PendingOrders/PendingOrderModal')
);

const SellerProductsListModal = webCore.helpers.dynamic(
  () => import('~components/Dashboard/SellerProductsListModal')
);
const ReviewProductModal = webCore.helpers.dynamic(
  () => import('~components/ReviewProductModal')
);

const AnswerReviewModal = webCore.helpers.dynamic(
  () => import('~components/Seller/ReviewCommentList/AnswerReviewModal')
);

const DeliveryProcessModal = webCore.helpers.dynamic(
  () => import('~components/Seller/ReviewCommentList/DeliveryProcessModal')
);

const DeleteProductsModal = webCore.helpers.dynamic(
  () => import('~components/Seller/Products/DeleteProductsModal')
);

const SellerProductDetailedQuantityModal = webCore.helpers.dynamic(
  () => import('~components/Seller/ProductDetailed/EditQuantityModal')
);

const SellerProductDetailedPriceModal = webCore.helpers.dynamic(
  () => import('~components/Seller/ProductDetailed/EditPriceModal')
);

const SellerProductDetailedDiscountedPriceModal = webCore.helpers.dynamic(
  () => import('~components/Seller/ProductDetailed/DiscountedPriceModal')
);

const SellerAddCollaboratorsModal = webCore.helpers.dynamic(
  () => import('~components/Seller/Collaborators/AddCollaboratorsModal')
);

const SellerSuspendCollaboratorsModal = webCore.helpers.dynamic(
  () => import('~components/Seller/Collaborators/SuspendModal')
);

const SellerForgotPasswordModal = webCore.helpers.dynamic(
  () => import('~components/Seller/Account/Security/ForgotPassword')
);

const SellerResetPasswordModal = webCore.helpers.dynamic(
  () => import('~components/Seller/Account/Security/ResetPassword')
);

const ConfirmSignUpModal = webCore.helpers.dynamic(
  () => import('~pages/dashboard/ConfirmSignUp')
);

const modals = {
  [webApp.constants.ENUM_MODAL_SELLER_ORDER_DETAILS]: SellerOrderDetailsModal,
  [webApp.constants.ENUM_MODAL_ORDER_DETAILS]: OrderDetailsModal,
  [webApp.constants.ENUM_MODAL_PENDING_ORDER_APPROVE]: PendingOrderModal,
  [webApp.constants.ENUM_MODAL_EDIT_SELLER_PRODUCTS_LIST]:
    SellerProductsListModal,
  [webApp.constants.ENUM_MODAL_REVIEW_PRODUCT]: ReviewProductModal,
  [webApp.constants.ENUM_MODAL_ANSWER_REVIEW]: AnswerReviewModal,
  [webApp.constants.ENUM_MODAL_DELIVERY_PROCESS]: DeliveryProcessModal,

  [webApp.constants.ENUM_MODAL_SELLER_DELETE_PRODUCTS]: DeleteProductsModal,

  [webApp.constants.ENUM_MODAL_SELLER_PRODUCT_DETAILED_QUANTITY]:
    SellerProductDetailedQuantityModal,

  [webApp.constants.ENUM_MODAL_SELLER_PRODUCT_DETAILED_PRICE]:
    SellerProductDetailedPriceModal,

  [webApp.constants.ENUM_MODAL_SELLER_PRODUCT_DETAILED_DISCOUNTED_PRICE]:
    SellerProductDetailedDiscountedPriceModal,

  [webApp.constants.ENUM_MODAL_SELLER_ADD_COLLABORATORS]:
    SellerAddCollaboratorsModal,

  [webApp.constants.ENUM_MODAL_SUSPEND_COLLABORATORS]:
    SellerSuspendCollaboratorsModal,

  [webApp.constants.ENUM_MODAL_SELLER_FORGOT_PASSWORD]:
    SellerForgotPasswordModal,

  [webApp.constants.ENUM_MODAL_SELLER_RESET_PASSWORD]: SellerResetPasswordModal,
  [webApp.constants.ENUM_MODAL_SELLER_CONFIRM_SIGN_UP]: ConfirmSignUpModal,
};

export default modals;
