import webCore from '@happylife-a/web-core';

const DashboardLayout = webCore.helpers.dynamic(
  () => import('~layouts/Dashboard')
);

// account
const AccountPersonalInfo = webCore.helpers.dynamic(
  () => import('~pages/account/personal-info')
);
const AccountBankDetails = webCore.helpers.dynamic(
  () => import('~pages/account/bank-details')
);
const AccountSecurity = webCore.helpers.dynamic(
  () => import('~pages/account/security')
);

// dashboard
const Dashboard = webCore.helpers.dynamic(
  () => import('~pages/dashboard/index')
);

// order list
const OrderList = webCore.helpers.dynamic(
  () => import('~pages/order-list/index')
);
const OrderInfo = webCore.helpers.dynamic(
  () => import('~pages/order-list/[id]')
);

// review form
const ReviewForm = webCore.helpers.dynamic(
  () => import('~pages/review-form/index')
);

// products
const Products = webCore.helpers.dynamic(() => import('~pages/products/index'));
const ProductsViewById = webCore.helpers.dynamic(
  () => import('~pages/products/view/[id-or-slug]')
);

// pending product
const PendingProducts = webCore.helpers.dynamic(
  () => import('~pages/pending-products/index')
);

// edit product
const EditProduct = webCore.helpers.dynamic(
  () => import('~pages/products/[id-or-slug]/edit')
);

// create product
const CreateProduct = webCore.helpers.dynamic(
  () => import('~pages/create/index')
);
const CreateProductSingleIndex = webCore.helpers.dynamic(
  () => import('~pages/create/single/index')
);
const CreateProductSingleById = webCore.helpers.dynamic(
  () => import('~pages/create/single/[id-or-slug]')
);
const CreateProductVariationalIndex = webCore.helpers.dynamic(
  () => import('~pages/create/variational/index')
);
const CreateProductVariationalById = webCore.helpers.dynamic(
  () => import('~pages/create/variational/[id-or-slug]')
);

// pending orders
const PendingOrders = webCore.helpers.dynamic(
  () => import('~pages/pending-orders')
);

// review comments
const ReviewComments = webCore.helpers.dynamic(
  () => import('~pages/review-comments/index')
);

// draft
const Draft = webCore.helpers.dynamic(() => import('~pages/draft/index'));

// privacy policy
const PrivacyPolicy = webCore.helpers.dynamic(
  () => import('~pages/privacy-policy/index')
);
const TermsAndConditions = webCore.helpers.dynamic(
  () => import('~pages/terms-and-conditions/index')
);

// products
const Collaborators = webCore.helpers.dynamic(
  () => import('~pages/collaborators/index')
);

// seller support
const SellerSupport = webCore.helpers.dynamic(
  () => import('~pages/seller-support/index')
);

const screens = [
  // account
  {
    pathname: '/account/personal-info',
    screen: AccountPersonalInfo,
    layout: DashboardLayout,
  },
  {
    pathname: '/account/bank-details',
    screen: AccountBankDetails,
    layout: DashboardLayout,
  },
  {
    pathname: '/account/security',
    screen: AccountSecurity,
    layout: DashboardLayout,
  },

  // dashboard
  {
    pathname: '/dashboard',
    screen: Dashboard,
    layout: DashboardLayout,
  },

  // order list
  {
    pathname: '/order-list',
    screen: OrderList,
  },
  {
    pathname: '/order-list/:id',
    screen: OrderInfo,
  },

  // review form
  {
    pathname: '/review-form',
    screen: ReviewForm,
    layout: DashboardLayout,
  },

  // products
  {
    pathname: '/products',
    screen: Products,
    layout: DashboardLayout,
  },

  {
    pathname: '/products/view/:idOrSlug',
    screen: ProductsViewById,
    layout: DashboardLayout,
  },

  // pending products
  {
    pathname: '/pending-products',
    screen: PendingProducts,
    layout: DashboardLayout,
  },
  // edit product details
  {
    pathname: '/products/:idOrSlug/edit',
    screen: EditProduct,
  },

  // create product
  {
    pathname: '/create',
    screen: CreateProduct,
    layout: DashboardLayout,
  },
  {
    pathname: '/create/single',
    screen: CreateProductSingleIndex,
  },
  {
    pathname: '/create/single/:idOrSlug',
    screen: CreateProductSingleById,
  },
  {
    pathname: '/create/variational',
    screen: CreateProductVariationalIndex,
  },
  {
    pathname: '/create/variational/:idOrSlug',
    screen: CreateProductVariationalById,
  },

  // pending orders
  {
    pathname: '/pending-orders',
    screen: PendingOrders,
  },

  // draft
  {
    pathname: '/draft',
    screen: Draft,
  },

  {
    pathname: '/privacy-policy',
    screen: PrivacyPolicy,
    layout: DashboardLayout,
  },
  {
    pathname: '/terms-and-conditions',
    screen: TermsAndConditions,
    layout: DashboardLayout,
  },
  {
    pathname: '/review-comments',
    screen: ReviewComments,
  },
  {
    pathname: '/collaborators',
    screen: Collaborators,
  },

  {
    pathname: '/seller-support',
    screen: SellerSupport,
  },

  ...(['seller.stg.happylife.am', 'seller.happylife.am'].includes(
    window.location.hostname
  )
    ? []
    : // @INFO: store routes in the array below if you want these routes to be available only on dev and store them in array above if you want them to be available only on stage and production
      []),
];

export default screens;
