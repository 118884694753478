/* eslint-disable import/order */

import appBranding from '@happylife-a/branding';
import webApp from '@happylife-a/web-app';

// branding + configs
import configBranding from '../.app-branding/configs/branding.json';
import configOrder from '../.app-branding/configs/order.json';
import configCountryParams from '../.app-branding/configs/country-params.json';
import configFeatures from '../.app-branding/configs/features.json';
import configPayment from '../.app-branding/configs/payment.json';
import configDelivery from '../.app-branding/configs/delivery.json';
import configPlatformSettings from '../.app-branding/configs/platform-settings.json';
import configProductSource from '../.app-branding/configs/product-source.json';
import configLegalInfo from '../.app-branding/configs/legal-info.json';
import configContactsAndLinks from '../.app-branding/configs/contacts+links.json';

// ui
import uiTheming from '../.app-branding/ui/theming.json';
import uiSettings from '../.app-branding/ui/settings.json';

// mobile app
import mobileApp from '../.app-branding/mobile/mobile-app.json';

// contents - policies
import configContentPrivacyPolicy from '../.app-branding/contents/policies/privacy-policy.json';
import configContentTermsConditions from '../.app-branding/contents/policies/terms-conditions.json';
import configContentDeliveryPolicy from '../.app-branding/contents/policies/delivery-policy.json';
import configContentReturnPolicy from '../.app-branding/contents/policies/return-policy.json';

// contents - translations
import configTranslationEn from '../.app-branding/contents/translations/en.json';
import configTranslationHy from '../.app-branding/contents/translations/hy.json';
import configTranslationRu from '../.app-branding/contents/translations/ru.json';

appBranding.initiate({
  // branding + configs
  branding: configBranding,
  configs: {
    order: configOrder,
    countryParams: configCountryParams,
    features: configFeatures,
    payment: configPayment,
    delivery: configDelivery,
    platformSettings: configPlatformSettings,
    productSource: configProductSource,
    legalInfo: configLegalInfo,
    contactsAndLinks: configContactsAndLinks,
  },

  // ui
  ui: {
    theming: uiTheming,
    settings: uiSettings,
  },

  // mobile app
  mobileApp: mobileApp,

  // contents
  contents: {
    translations: {
      en: configTranslationEn,
      hy: configTranslationHy,
      ru: configTranslationRu,
    },
    policies: {
      privacyPolicy: configContentPrivacyPolicy,
      termsConditions: configContentTermsConditions,
      deliveryPolicy: configContentDeliveryPolicy,
      returnPolicy: configContentReturnPolicy,
    },
  },
});

webApp.theme.initWithBranding(uiTheming.theme);
webApp.configure('SellerDashboard');

export { appBranding };
