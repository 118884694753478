import webCore from '@happylife-a/web-core';

const AuthLayout = webCore.helpers.dynamic(() => import('~layouts/Auth'));

// auth & password
const Login = webCore.helpers.dynamic(() => import('~pages/dashboard/login'));
const RecoveryPassword = webCore.helpers.dynamic(
  () => import('~pages/dashboard/recover-password/index')
);
const ResetPassword = webCore.helpers.dynamic(
  () => import('~pages/dashboard/reset-password/index')
);
const SuccessPage = webCore.helpers.dynamic(
  () => import('~pages/dashboard/success')
);

const screens = [
  // auth & password
  {
    pathname: '/dashboard/login',
    screen: Login,
    layout: AuthLayout,
  },
  {
    pathname: '/dashboard/recover-password',
    screen: RecoveryPassword,
    layout: AuthLayout,
  },
  {
    pathname: '/dashboard/reset-password',
    screen: ResetPassword,
    layout: AuthLayout,
  },
  {
    pathname: '/dashboard/success-recover-password',
    screen: SuccessPage,
    layout: AuthLayout,
  },
];

export default screens;
